/* global gtag */
import * as Sentry from '@sentry/browser';

/**
 * Various utilities
 */

function switchPlaceholder(item) {
    // TODO: comment
    const oldPlaceholder = $(item).attr('placeholder');
    $(item).attr('placeholder', $(item).attr('data-placeholder'));
    $(item).attr('data-placeholder', oldPlaceholder);
}

function initializeSentry() {
    const dsn = window.__FRONTEND_SENTRY_DSN__;

    if (dsn && dsn.startsWith('https')) {
        Sentry.init({
            dsn,
            whitelistUrls: [/vumedi\.com/],
            maxValueLength: 500,
        });

        const userContext = window.__SENTRY_USER_CONTEXT__ || {};
        Sentry.configureScope((scope) => {
            scope.setUser(userContext);
        });
    }
}

function trackGoogleAnalyticsLinkClicks() {
    // eslint-disable-next-line func-names
    $('li a').click(function () {
        const innerText = $(this).prop('innerText');
        const className = $(this).prop('className');
        gtag('event', `${className}_click`, { event_category: 'Clicks', event_label: innerText });
    });
}

function toggleStaticPageTabs() {
    const tabs = document.querySelectorAll('.js_staticPageTab');

    tabs.forEach((tab) => {
        tab.addEventListener('click', () => {
            tab.classList.toggle('is-expanded');
        });
    });
}

function getComments() {
    // Fetch comments
    $('[data-event="get-comments"]').each((index, el) => {
        const { contentType, contentId, count } = el.dataset;
        const data = typeof count !== 'undefined' ? { count } : {};

        $.ajax({
            url: `/comments/${contentType}/${contentId}/`,
            data,
            dataType: 'html',
            success(resp) {
                $(`.comments-${contentType}-${contentId}`).html(resp);
                // Need to set explicit height because of brand alerts
                const commentsWrapper = document.querySelector('.js_commentsWrapper');
                commentsWrapper.style.height = `${commentsWrapper.clientHeight}px`;
            },
        });
    });
}

function fetchAndAppendToBody(url) {
    $.get({ url }).then((data) => {
        $('body').append(data);
    });
}

/** Modal handler logic - see UpdateInterstitialView and related classes for more info. */
function handleModals() {
    const { changeSiteURL, updatePracticeSettingURL, updateSubspecialtySubscriptionURL } = window.__VUMEDI_CONTEXT__;

    if (changeSiteURL) {
        fetchAndAppendToBody(changeSiteURL);
    }
    if (updatePracticeSettingURL) {
        fetchAndAppendToBody(updatePracticeSettingURL);
    }
    if (updateSubspecialtySubscriptionURL) {
        fetchAndAppendToBody(updateSubspecialtySubscriptionURL);
    }
}

/**
 * Function used to set a value for CSS variables which are used on the LS page, search page and browse term page
 * to expand the sidebars height.
 */
const dynamicFiltersListHeight = () => {
    if (!document.querySelector('.js_dynamicFiltersList') || window.innerWidth < 992) return;

    const filtersHeading = document.querySelector('.js_filtersHeading');
    const filterList = document.querySelector('.js_dynamicFiltersList');
    let filterListTopOffset = 0;

    setTimeout(() => {
        filterListTopOffset = filterList.getBoundingClientRect().top;
        document.documentElement.style.setProperty('--filter-list-top-offset', `${filterListTopOffset}px`);

        if (filtersHeading) {
            document.documentElement.style.setProperty('--search-term-height', `${filtersHeading.clientHeight}px`);
        }
    }, 100);

    window.onscroll = () => {
        const { scrollY } = window;
        filterListTopOffset = filterList.getBoundingClientRect().top;

        document.documentElement.style.setProperty('--page-scroll-amount', `${scrollY}px`);
        document.documentElement.style.setProperty('--filter-list-top-offset', `${filterListTopOffset}px`);
    };
};

/**
 * Main listeners
 */

document.addEventListener('DOMContentLoaded', (ev) => {
    initializeSentry();
    trackGoogleAnalyticsLinkClicks();
    toggleStaticPageTabs();
    handleModals();
    dynamicFiltersListHeight();
    getComments(ev);

    // Throttle a default click event listener on html tags with class .throttled-click.
    $('.throttled-click').on('click', window.throttledDefaultListener(10000));

    // Enable tooltip components
    $('[data-toggle="tooltip"]').tooltip();

    // Remove the autocomplete parameters from the search form on 'Search' submit
    $('input[value="Search"]').click(() => {
        $('input[name="q_0"]').attr('name', 'q');
        $('input[name="q_1"]').remove();
    });

    // Load autosize for textarea inputs which have 'inputexpander' data-effect attribute
    // Make sure autosize library is loaded.
    window.autosize($('[data-effect="inputexpander"]'));

    // It is used for toggling truncated content description
    $('.readmoreMore, .readmoreLess').on('click', () => {
        $('.readmoreMore, .readmoreLess').toggle();
    });

    // Video item image expansion on large screens
    const isLarge = () => $('#am-i-lg').is(':visible');

    if (!isLarge()) {
        $('.video-item__img-container.video-item__img-container-center').addClass('mw-100 mx-0');
        $('.video-item__img-container.video-item__img-container-center .video-item__img').css({
            'max-width': '100%',
            width: '100%',
        });
    }

    // Load images
    // eslint-disable-next-line func-names
    $('[data-event="get-images"]').each(function () {
        const type = $(this).attr('data-type');
        const id = $(this).attr('data-object-id');

        $.ajax({
            url: `/images/${type}/${id}/`,
            success(resp) {
                $(`#images-${type}-${id}`).html(resp.images);
            },
            error(jqXHR) {
                Sentry.captureException(jqXHR);
            },
        });
    });

    // 'Add Choice' field on Polls
    $('#add-field').click((e) => {
        e.preventDefault();
        const field = $('#add-choice-field').html();
        $('#choices').append(field);
    });

    // TODO: comment
    $('#create-poll').click((e) => {
        e.preventDefault();
        $.ajax({
            url: $('#poll-form').attr('action'),
            type: 'POST',
            data: $('#poll-form').serialize(),
            headers: { 'X-CSRFToken': window.csrftoken() },
            success(resp) {
                if (resp.status) {
                    $('#poll-id').attr('value', resp.poll_id);
                    $('#poll-display').html(resp.question);
                    $('#add-poll').modal('hide');
                    $('a[href="#add-poll"]').hide();
                }
            },
        });
    });

    // eslint-disable-next-line func-names
    $('.video-listing div.media a').click(function () {
        $(this).click((clickEv) => {
            clickEv.preventDefault();
            setTimeout(() => {
                $('.video-related div.media a').unbind('click');
            }, 3000);
        });
    });
});

document.addEventListener('myCustomEvent', (ev) => {
    // TODO: Refactor/Rename myCustomEvent
    // Get comments on myCustomEvent
    getComments(ev);
});

// eslint-disable-next-line func-names
$(document).on('click', '.thumbup', function (ev) {
    ev.preventDefault();

    const postURL = $(this).attr('href');
    const id = $(this).parent().attr('id');
    const csrftoken = $(this).find('form').serialize();

    $.ajax({
        type: 'GET',
        url: postURL,
        data: csrftoken,
        success: (resp) => {
            if (!resp) return;

            const count = parseInt(resp.count, 10);

            $(`#${id}`).html(resp.button);
            if (count > 0) {
                $(`#${id}-count .thumbup-count`).html(count);
                $(`#${id}-count .thumbup-count-icon`).show();
            } else {
                $(`#${id}-count .thumbup-count`).html('');
                $(`#${id}-count .thumbup-count-icon`).hide();
            }
        },
    });
});

$(() => {
    // Post a new Tab form
    $('#tab_form_submit').click((ev) => {
        ev.preventDefault();

        $.ajax({
            url: `${window.location.pathname}tab/`,
            data: $('#tab_form').serialize(),
            type: 'POST',
            headers: { 'X-CSRFToken': window.csrftoken() },
            success(resp) {
                if (resp.status) {
                    const alertMessage = `<div data-alert="alert" class="fade in alert ${resp.add_class}"><button type="button" class="close" data-dismiss="alert">x</button>${resp.message}</div>`;
                    $('#alerts').append(alertMessage);
                    window.setTimeout(() => {
                        $('.alert')
                            .fadeTo(500, 0)
                            // eslint-disable-next-line func-names
                            .slideUp(500, function () {
                                $(this).remove();
                            });
                    }, 4000);
                    $('#tab_modal_form').modal('hide');
                }
            },
        });
    });
});

// eslint-disable-next-line func-names
$(document).on('click', '[data-toggle="show-rest"]', function () {
    switchPlaceholder($(this));
    const target = $(this).attr('data-target');
    $(target).removeClass('hide');
});

// eslint-disable-next-line func-names
$(document).on('click', '[data-toggle="show-rest-cancel"]', function (ev) {
    // TODO: comment
    ev.preventDefault();
    let item = `#${$(this).attr('data-update-placeholder')}`;
    switchPlaceholder($(item));
    item = `#${$(this).attr('data-target')}`;
    $(item).addClass('hide');
});

// eslint-disable-next-line func-names
$(document).on('click', '[data-toggle="show"]', function () {
    $($(this).attr('data-target')).show();
});

/**
 * [LEGACY WARNING - AVOID]
 *
 * The intention of the following line of code is to have a global mechanism that adds `active` class to any
 * `<li>` element on the website that contains a link to the current location/URL.
 *
 * TODO: Remove and add appropriate logic to views/components/... where `active` functionality is needed.
 *
 * Possible approach: rewrite following line in native JS, and when adding a class, send a Sentry event or
 *          something similar to identify all use-cases of this code.
 */
$(`li > a[href="${window.location.pathname}"]`).parent('li').addClass('active');

// Prevent ekkoLightbox images default event
// eslint-disable-next-line func-names
$(document).on('click', '[data-toggle="lightbox"]', function (ev) {
    ev.preventDefault();
    $(this).ekkoLightbox();
});

// Poll voting!
// eslint-disable-next-line func-names
$(document).on('click', 'input[name^="poll-choice-"]', function () {
    const choiceURL = $(this).attr('data-target');

    $.ajax({
        url: choiceURL,
        type: 'POST',
        headers: { 'X-CSRFToken': window.csrftoken() },
        success(resp) {
            $.each(resp.results, (key, value) => {
                $(`.result-poll-${key}`).width(`${value.percent}%`);
                if (value.number) {
                    $(`.result-poll-${key}`).html(value.number);
                } else {
                    $(`.result-poll-${key}`).html('');
                }
            });
        },
        error(jqXHR) {
            Sentry.captureException(jqXHR);
        },
    });
});
